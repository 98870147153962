<template>
  <InvoicesTemplate
    :apiActions="apiActions"
    getInvoicesUrl="invoices/shopify/list"
    :invoiceStatusesList="invoiceStatusesList" />
</template>
<script setup>
import InvoicesTemplate from "@/components/invoices/InvoicesTemplate";

const apiActions = {
  sendRequest: "sendShopifyInvoicesRequest",
  downloadRequest: "downloadShopifyInvoicesRequest",
  syncRequests: {
    default: "syncShopifyInvoicesRequest",
    subType: false,
  },
};

const invoiceStatusesList = [
  { title: "All", value: "" },
  { title: "Pending", value: "pending" },
  { title: "Paid", value: "paid" },
  { title: "Voided", value: "voided" },
];
</script>
